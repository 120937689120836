const extensionId = window.appConfig?.EXTENSION_ID;

export function getImpersonatedProducerId() {
  return localStorage.getItem('impersonatedProducerId') ?? undefined;
}

export function getIsExtensionInstalled() {
  // Chrome API call guard
  // eslint-disable-next-line no-undef
  if (!window || !('chrome' in window) || !chrome.runtime) {
    return false;
  }

  // Chrome API call guarded
  // eslint-disable-next-line no-undef
  const port = chrome.runtime.connect(extensionId);
  if (!port) {
    return false;
  }

  port.disconnect();

  return true;
}

export function getIsImpersonateMode() {
  return !!localStorage.getItem('isImpersonatedDataPresent');
}
