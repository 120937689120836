import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import ProcessFooter from '../../components/process-footer';
import ProcessHeader from '../../components/process-header';
import { preSignupUser, preSignupUserSuccess } from 'redux/actions/pre-sign-up';
import { setDashboardView, setInitUrl } from '../../redux/actions/auth';
import asyncComponent from '../../util/async-component';
import PostSignupProcess from './post-signup-process';
import { setSignupByReferral } from 'redux/actions/post-sign-up';
import { allowedParams, queryParam } from 'util/extra';
import { updateAgentNameInSignUpProcess } from 'redux/actions/dashboard';
import { Helmet } from 'react-helmet';
import { NewSignupRedirectWrapper } from 'containers/post-signup/post-signup-process/new-sign-up-redirect-wrapper';

const PostSignup = ({
  dashboard,
  history,
  isAgreementAccepted,
  match,
  updateDefaultPassword,
  userRole,
  ...props
}) => {
  const location = useLocation();
  const currentPathName = location.pathname;
  const query = new URLSearchParams(props.location?.search);
  const filteredParams = new URLSearchParams();

  for (const [key, value] of query) {
    if (allowedParams.includes(key)) {
      filteredParams.append(key, value);
    }
  }

  useEffect(() => {
    const visitedSubmitConfirmation =
      JSON.parse(localStorage.getItem('visitedSubmitConfirmation')) || false;
    if (visitedSubmitConfirmation && !dashboard) {
      props.setDashboardView(true);
    }
  }, []);

  useEffect(() => {
    if (updateDefaultPassword) {
      history.push('/post-signup/update-new-password');
    } else if (dashboard) {
      history.push(`/app/${userRole}`);
    }
  }, [
    dashboard,
    history,
    isAgreementAccepted,
    updateDefaultPassword,
    userRole,
  ]);

  while (match.url.endsWith('/')) {
    match.url = match.url.slice(0, -1);
  }

  useEffect(() => {
    queryParam(props);
  }, [history]);

  useEffect(() => {
    if (
      currentPathName === '/post-signup/process' &&
      !props.history &&
      props.location?.search
    ) {
      window.location.replace(`/post-signup?${filteredParams}`);
    }
  }, []);
  return (
    <NewSignupRedirectWrapper>
      <div className="app-container post-signup-wrapper">
        <Helmet defaultTitle="First Connect">
          <title>Get Started in Minutes</title>
          <meta
            name="description"
            content="Access 120+ top insurance carriers with our easy to use platform. Get instant access with select carriers, all at no cost to you."
          />
          <meta
            name="keywords"
            content="sign up, register, start, agent portal"
          />
        </Helmet>
        <div className="app-main-container">
          <div className="app-header">
            <ProcessHeader />
          </div>
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Redirect
                  exact
                  from={`${match.url}`}
                  to={`${match.url}/process${props.location.search}`}
                />
                <Route
                  exact
                  path={`${match.url}/process`}
                  component={PostSignupProcess}
                />
                <Route
                  exact
                  path={`${match.url}/agreement-acceptance`}
                  component={asyncComponent(() =>
                    import('./agreement-acceptance')
                  )}
                />
                <Route
                  exact
                  path={`${match.url}/update-new-password`}
                  component={asyncComponent(() =>
                    import('./update-new-password')
                  )}
                />
                <Route
                  exact
                  path={`${match.url}/member`}
                  component={asyncComponent(() => import('./new-member'))}
                />
                <Route
                  component={asyncComponent(() =>
                    import('components/error-404')
                  )}
                />
              </Switch>
            </div>
            <ProcessFooter />
          </main>
        </div>
      </div>
    </NewSignupRedirectWrapper>
  );
};

const mapStateToProps = ({
  auth: { dashboard, updateDefaultPassword, userRole },
  postSignup: { isAgreementAccepted },
}) => ({
  dashboard,
  isAgreementAccepted,
  updateDefaultPassword,
  userRole,
});

export default connect(mapStateToProps, {
  setSignupByReferral,
  preSignupUserSuccess,
  setInitUrl,
  preSignupUser,
  setDashboardView,
  updateAgentNameInSignUpProcess,
})(PostSignup);
