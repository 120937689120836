import { useCallback, useEffect } from 'react';
import { getToken } from "../../util/auth/get-token";
import { getImpersonatedProducerId, getIsExtensionInstalled, getIsImpersonateMode } from "./with-extension.utils";


const extensionId = window.appConfig?.EXTENSION_ID;

/**
 * This component is used to handle the communication between the portal and the extension.
 */
export default function WithExtension({ children }) {
  const isExtensionInstalled = getIsExtensionInstalled();

  const handleSendTokenToExtension = useCallback(() => {
    if (!isExtensionInstalled) {
      return;
    }

    if (!extensionId) {
      return;
    }

    void getToken().then((token) => {
      let impersonatedProducerId;
      if (getIsImpersonateMode()) {
        impersonatedProducerId = getImpersonatedProducerId();
      }

      // Chrome API call is guarded by `isExtensionInstalled`
      // eslint-disable-next-line no-undef
      void chrome.runtime.sendMessage(extensionId, {
        token,
        impersonatedProducerId,
      });
    });
  }, [isExtensionInstalled]);

  useEffect(() => {
    if (!isExtensionInstalled || !extensionId) {
      return;
    }

    // Try sending a token to the plugin immediately if a pop-up is open before the portal
    // (e.g. a pop-up triggers portal tab to open)
    handleSendTokenToExtension();
    // Listen to custom event when plugin requests a token
    window.addEventListener('onGetToken', handleSendTokenToExtension);

    return () => {
      window.removeEventListener('onGetToken', handleSendTokenToExtension);
    };
  }, [handleSendTokenToExtension, isExtensionInstalled]);

  return children;
}