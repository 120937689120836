import React, { Component } from 'react';
import {
  CardActions,
  CardContent,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

import SubmitLoader from 'components/submit-loader';
import IntlMessages from 'util/intl-messages';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  ALTERNATE_SUB_PRODUCER,
  EMAIL_CONFLICT,
  SUB_PRODUCER_KEY,
  TYPE_PASSWORD,
  TYPE_TEXT,
} from 'constants/constant';
import { v4 } from 'uuid';
import { blurHandlers, validators } from 'util/form-handler';
import { preSignupUser } from 'redux/actions';
import {
  getProgressBarFlag,
  setDashboardView,
  setInitUrl,
} from 'redux/actions/auth';
import { teamMemberEmailDuplicate as setTeamMemberEmailDuplicate } from 'redux/actions/my-account';
import {
  setCurrentStepIndex,
  stepSubmit,
  stepSubmitSuccess,
  submitLoaderHide,
} from 'redux/actions/post-sign-up';
import hippo from 'assets/images/Hippo-Carrier.svg';
import next from 'assets/images/Next.svg';
import kemper from 'assets/images/Kemper.svg';
import cna from 'assets/images/CNA.svg';
import nationWide from 'assets/images/Nationwide.svg';
import nationalGeneral from 'assets/images/National-General.svg';
import coterie from 'assets/images/Coterie.svg';
import biBerk from 'assets/images/biBERK.svg';
import bristolWest from 'assets/images/Bristol-West.svg';
import aegis from 'assets/images/Aegis.svg';
import {
  executeRecaptchaCheck,
  hideRecaptchaBadge,
  showRecaptchaBadge,
} from 'util/recaptcha';

const initState = {
  fieldsModified: false,
  formValues: {
    firstName: '',
    email: '',
    lastName: '',
    phone: '',
    password: '',
    SSN: '',
    address: '',
    addressState: '',
    city: '',
    zipCode: '',
  },
  formErrors: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    SSN: '',
    address: '',
    addressState: '',
    city: '',
    zipCode: '',
  },
  showPassword: false,
};
const LogoCarrierFirst = [
  hippo,
  nationWide,
  bristolWest,
  nationalGeneral,
  biBerk,
];
const LogoCarrierSecond = [cna, coterie, next, aegis, kemper];

/**
 * Sign Up with Password
 *
 * This component has a copy named Sign Up Passwordless, with the password field removed.
 * When doing any adjustments, make sure to apply them to the Sign Up Passwordless component as well.
 *
 * TODO - In the future, replace this component with the passwordless one.
 */
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.isSubProducerWithProductionAccess =
      props.productionAccess && props.signupFlow === SUB_PRODUCER_KEY;
    this.alternateSubWithProdAcc =
      props.productionAccess && props.signupFlow === ALTERNATE_SUB_PRODUCER;
    this.state = initState;
    this.errorField = null;
    this.createRef();
  }

  componentDidMount = () => {
    const {
      agencyProfile: {
        firstName,
        email,
        lastName,
        password,
        phone,
        address,
        city,
        state: addressState,
        zipCode,
      },
      tax: { SSN },
      submitLoader,
      isSignedUpByReferral,
      referralData,
      teamMemberEmailDuplicate,
    } = this.props;

    let newState = {
      isMount: true,
      formValues: {
        ...this.state.formValues,
        email,
        firstName,
        lastName,
        phone,
        password,
        SSN,
        address,
        addressState,
        city,
        zipCode,
      },
    };
    if (isSignedUpByReferral) {
      newState.formValues.firstName = referralData.first_name;
      newState.formValues.lastName = referralData.last_name;
      newState.formValues.agencyLegalName = referralData.agency_name;
      newState.formValues.phone = referralData.phone_number;
      newState.formValues.email = referralData.email;
      newState.formValues.utm_source = referralData.utm_source;
      newState.formValues.referred_code = referralData.referred_code;
    }
    this.setState(newState);
    submitLoader && this.props.submitLoaderHide();
    if (teamMemberEmailDuplicate?.messageType) {
      this.props.setTeamMemberEmailDuplicate({
        success: false,
        message: null,
        messageType: null,
      });
    }
    if (
      !localStorage.getItem('uuid') &&
      !localStorage.getItem('Authorization')
    ) {
      const uuid = v4();
      localStorage.setItem('uuid', uuid);
      this.props.getProgressBarFlag(uuid);
    }

    // Show reCAPTCHA badge <https://myhippo.atlassian.net/browse/FRC-5951>
    if (window.location.pathname === '/post-signup/process') {
      showRecaptchaBadge();
    }
  };

  componentWillUnmount() {
    // Hide reCAPTCHA badge <https://myhippo.atlassian.net/browse/FRC-5951>
    hideRecaptchaBadge();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let formErrors = { ...this.state.formErrors };
    formErrors = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
    };
    if (
      !newProps.teamMemberEmailDuplicate?.success &&
      newProps.teamMemberEmailDuplicate?.messageType === EMAIL_CONFLICT
    ) {
      this.setState((prevState) => ({
        ...prevState,
        formErrors: {
          ...formErrors,
          email: newProps.teamMemberEmailDuplicate?.message,
        },
      }));
    }
  }

  createRef() {
    this.refList = {
      firstName: React.createRef(),
      lastName: React.createRef(),
      email: React.createRef(),
      phone: React.createRef(),
      password: React.createRef(),
    };
  }

  validators = {
    firstName: [
      (value) => {
        if (this.props.isSubProducer) {
          return validators.required(value), validators.nameInvalid(value);
        }
        return '';
      },
    ],
    lastName: [
      (value) => {
        if (this.props.isSubProducer) {
          return validators.required(value), validators.nameInvalid(value);
        }
        return '';
      },
    ],
    email: [
      (value) => {
        if (!this.props.isSubProducer) {
          return validators.required(value) || validators.emailInvalid(value);
        }
        return '';
      },
    ],
    phone: [
      (value) => {
        if (this.props.isSubProducer) {
          return validators.required(value) || validators.usPhoneInvalid(value);
        }
        return '';
      },
    ],
    address: [
      (value) => {
        if (this.props.isSubProducer) {
          return validators.required(value);
        }
        return '';
      },
    ],
    city: [
      (value) => {
        if (this.props.isSubProducer) {
          return validators.required(value);
        }
        return '';
      },
    ],
    addressState: [
      (value) => {
        if (this.props.isSubProducer) {
          return validators.selectRequired(value);
        }
        return '';
      },
    ],
    zipCode: [
      (value) => {
        if (this.props.isSubProducer) {
          return validators.required(value) || validators.zipCodeInvalid(value);
        }
        return '';
      },
    ],
    SSN: [
      // check only if subproducer with production access
      (value) => {
        if (
          this.isSubProducerWithProductionAccess &&
          !this.props.writesUnderAgencyLicense
        ) {
          return validators.required(value) || validators.SSNInvalid(value);
        }
        return '';
      },
    ],
    password: [
      (value) => {
        if (
          this.props.isSubProducer ||
          (this.props.signupStatus && !this.props.isSignedUpByReferral)
        ) {
          return '';
        }
        return validators.required(value) || validators.passwordInvalid(value);
      },
    ],
  };

  validate = () => {
    const { formValues, formErrors } = this.state;
    let isInvalid = false;
    const newErrors = { ...formErrors };

    for (const [key, validatorList] of Object.entries(this.validators)) {
      for (const validator of validatorList) {
        const error = validator(formValues[key]);
        newErrors[key] = error;
        if (error) {
          isInvalid = true;

          if (!this.errorField) {
            this.errorField = key;
          }

          break;
        }
      }
    }

    this.setState({ formErrors: newErrors });

    return !isInvalid;
  };

  submitForm = async (event) => {
    event.preventDefault();
    const {
      formValues: { email, utm_source, referred_code },
    } = this.state;
    const { signupStatus, isSignedUpByReferral } = this.props;
    const flagshipIdentifier = localStorage.getItem('uuid');

    if (!this.validate()) {
      this.refList[this.errorField]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
      this.errorField = null;
      return;
    }

    if (
      !isSignedUpByReferral &&
      (Boolean(this.props.isSubProducer) || Boolean(signupStatus))
    ) {
      return;
    }

    try {
      const captchaToken = await executeRecaptchaCheck(
        this.props.googleReCaptchaProps
      );
      this.props.preSignupUser({
        signup_details: {
          email,
          captchaToken,
          utm_source,
          referred_code,
          flagshipIdentifier,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  setValue = (key, value, callback) => {
    this.setState(
      {
        fieldsModified: true,
        formValues: { ...this.state.formValues, [key]: value },
      },
      () => callback?.(key)
    );
  };

  handleClickShowPassword = () => {
    const { formValues } = this.state;

    this.setState({
      formValues: {
        ...formValues,

        showPassword: !formValues.showPassword,
      },
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  redirectToSignIn = () => {
    this.props.setInitUrl('/signin');
    this.props.history.push('/signin');
  };

  render() {
    const { submitLoader, signupStatus, isSignedUpByReferral, loader } =
      this.props;
    const { formErrors, formValues } = this.state;
    const isEnabled =
      formValues?.email?.length > 0 && formValues?.password?.length > 0;

    return (
      <Grid container className="signin-form signup-content">
        <Grid item xs={12} className="flex-center">
          <div className="signup-wrap">
            <Typography className="paragraph-text">
              Start selling <b>120+ best-in-class insurance carriers</b> with
              our easy to use platform.{' '}
              <span className="display-block-lbl">
                Earn exclusive start up bonuses and competitive commissions with{' '}
                <b>no monthly fees!</b>
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} className="flex-center">
          <form className="form-width">
            <div className="form-card" elevation={0}>
              <CardContent className="form-card-content">
                <div className="mx-auto">
                  <div className="heading">
                    <Typography className="login-label">
                      <IntlMessages id="label.signup" />
                    </Typography>
                  </div>
                  <div className="row">
                    <div className="input-field mb-40 col-sm-12 col-md-12 col-lg-12">
                      <input
                        id="email"
                        value={formValues.email}
                        type="text"
                        className={clsx('fs-exclude', 'validate', {
                          'error-border': formErrors.email,
                        })}
                        maxLength="50"
                        onChange={(event) =>
                          this.setValue('email', event.target.value)
                        }
                        disabled={loader}
                      />
                      <label htmlFor="email" className="active">
                        <IntlMessages id="label.email" />
                        <span className="required">*</span>
                      </label>
                      <div className="error-msg">{formErrors.email}</div>
                    </div>
                    <div className="input-field signup-pass mb-17 col-sm-12 col-md-12 col-lg-12">
                      <label
                        htmlFor="password"
                        className={clsx('active', 'password-field', {
                          'label-error-color': formErrors.password,
                        })}
                        ref={this.refList.password}
                      >
                        <IntlMessages id="label.password" />
                        <span className="required">*</span>
                      </label>
                      <Input
                        fullWidth
                        id={TYPE_PASSWORD}
                        name={TYPE_PASSWORD}
                        type={
                          formValues.showPassword ? TYPE_TEXT : TYPE_PASSWORD
                        }
                        value={
                          signupStatus && !isSignedUpByReferral
                            ? '********'
                            : formValues.password
                        }
                        className={clsx(
                          'fs-exclude',
                          'validate',
                          'active',
                          'password-field',
                          { 'error-border': formErrors.password }
                        )}
                        setValue={this.setValue}
                        onChange={(event) =>
                          this.setValue('password', event.target.value)
                        }
                        disabled={
                          submitLoader ||
                          loader ||
                          (signupStatus && !isSignedUpByReferral)
                        }
                        maxLength={30}
                        handleBlur={blurHandlers.trim}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              {formValues.showPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {formErrors.password ? (
                        <div className="error-msg height-20">
                          {formErrors.password}
                        </div>
                      ) : (
                        <FormHelperText
                          className="helper-text"
                          id="standard-weight-helper-text"
                        >
                          Use 8+ characters with uppercase, lowercase, and
                          numbers
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                </div>
              </CardContent>
              <CardActions className="p-0 login-button-wrap mob-view">
                <Typography className="signup-text-label">
                  Already Started?
                  <span className="resp-d-block">
                    <span
                      className="text-orange padding-left-1"
                      onClick={this.redirectToSignIn}
                    >
                      Log in
                    </span>{' '}
                    to complete your application.
                  </span>
                </Typography>
                <button
                  className={`${!isEnabled ? 'disable' : ''} login-btn`}
                  disabled={!isEnabled || submitLoader}
                  type="submit"
                  onClick={this.submitForm}
                >
                  <IntlMessages id="label.signup" />
                  {(submitLoader || loader) && (
                    <SubmitLoader className="ml-1" />
                  )}
                </button>
              </CardActions>
            </div>
          </form>
        </Grid>
        <Grid item xs={12} className="carrier-logo-grid">
          <div className="carrier-logo-wrap">
            {LogoCarrierFirst.map((value) => {
              return (
                <>
                  <div className="carrier-logo-div">
                    <img
                      src={value}
                      className="carrier-logo img-fluid"
                      alt="carrier Logo"
                    />
                  </div>
                </>
              );
            })}
          </div>
          <div className="carrier-logo-wrap">
            {LogoCarrierSecond.map((value) => {
              return (
                <>
                  <div className="carrier-logo-div">
                    <img
                      src={value}
                      className="carrier-logo img-fluid"
                      alt="carrier Logo"
                    />
                  </div>
                </>
              );
            })}
          </div>
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = ({
  postSignup: {
    agencyProfile,
    submitLoader,
    tax,
    currentStepIndex,
    producer_code: producerCode,
    isSignedUpByReferral,
    referralData,
  },
  preSignup: { signupStatus, loader },
  auth: { signupFlow, writesUnderAgencyLicense },
  myAccount,
}) => {
  const { teamMemberEmailDuplicate } = myAccount;
  return {
    agencyProfile,
    loader,
    currentStepIndex,
    producerCode,
    signupFlow,
    submitLoader,
    teamMemberEmailDuplicate,
    tax,
    isSignedUpByReferral,
    referralData,
    signupStatus,
    writesUnderAgencyLicense,
  };
};

export default connect(mapStateToProps, {
  stepSubmit,
  stepSubmitSuccess,
  setDashboardView,
  setCurrentStepIndex,
  submitLoaderHide,
  preSignupUser,
  setTeamMemberEmailDuplicate,
  getProgressBarFlag,
  setInitUrl,
})(withGoogleReCaptcha(SignUp));
