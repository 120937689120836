import * as FullStory from '@fullstory/browser';
import config from 'config';
import { push } from 'connected-react-router';
import * as authType from 'constants/action-types';
import {
  ADMIN,
  CARRIER_APPOINTMENT_REQ_COUNT,
  CARRIER_STORE_AGENT_APPLICATION_RESTRICTION_ENABLED,
  CARRIER_TOOLIP_STATE,
  CARRIER_TOOLTIP,
  CLOUD_FRONT_URL,
  IN_COMPLETE,
  MAPPING_POST_SIGNUP_STEPS_NAME,
  PRODUCER_KEY,
  SHOW_CONSENT_MODAL,
  TERMINATED,
} from 'constants/constant';
import { PORTAL } from 'constants/heap-events';
import { REFRESH_STATE_ROUTE } from 'constants/routes';
import { jwtDecode } from 'jwt-decode';
import { isArray, isEmpty, isUndefined } from 'lodash';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import {
  fetchProducerFlags,
  fetchProducerSettings,
  fetchQuoteConnectorSettings,
  setSignInCohortData,
  setUnseenNotificationsCount,
} from 'redux/actions';
import {
  setQ3DaysRemaining,
  setQ4DaysRemaining,
} from 'redux/actions/incentives';
import {
  storeQ2IncentiveInitialDetail,
  viewQ2IncentiveModal,
} from 'redux/actions/q2-incentive';
import axios from 'util/api';
import {
  handleAuth0SpaLoginRedirect,
  issueAuth0SpaPasswordLogin,
} from 'util/auth/auth0-password-flow-spa.utils';
import {
  issueAuth0SpaPasswordlessLoginEmail,
  issueAuth0SpaPasswordlessLoginPhone,
  issueAuth0SpaPasswordlessLoginUnverifiedEmail,
} from 'util/auth/auth0-passwordless-flow.utils';
import {
  getIsPasswordlessRememberMe,
  logAuthError,
} from 'util/auth/auth0.utils';
import { decryptModulePermissions } from 'util/extra';
import {
  cleanupRedirectCookieData,
  getRedirectUrlFromCookie,
} from 'util/redirect-url';
import { displayErrorMessage } from 'util/user-feedback';
import { getAuth0SpaClient } from '../../util/auth/auth0-spa-client';
import { decryptData, isHex } from '../../util/crypto';
import { IS_CARRIER_STORE_ENABLED_KEY } from '../../util/has-carrier-store-access';
import {
  authLoaderHide,
  authLoaderShow,
  getProgressBarLoader,
  setApplicationModal,
  setCarrierFloodRater,
  setCarrierTend,
  setCarrierTooltip,
  setCloudFrontUrl,
  setDashboardView,
  setInitUrl,
  setIsGlobalAuthLoaderShown,
  setIsUserEmailVerified,
  setIsWholesaler,
  setProducer,
  setProducerLoggedIn,
  setProgressBarFlag,
  setStateInitialized,
  showAuthMessage,
  updateCarrierAppointmentRequestCount,
  userSignInSuccess,
} from '../actions/auth';
import {
  renewalNotificationFun,
  setConsentTextFlag,
  setShowFastStartBanner,
  viewFastStartModal,
} from '../actions/dashboard';
import { errorHandler } from '../actions/error';
import { setModulePermissions } from '../actions/module-permissions';
import { setSignupTransLoginSuccess } from '../actions/organization-details';
import {
  postEmailVerificationSuccess,
  stepDataFetch,
} from '../actions/post-sign-up';
import { handleSignInTracking, isInPasswordlessCohort } from './auth.sign-in';
import { signOutSagaWatcher } from './auth.sign-out';
import { AUTH_FLOW_PASSWORDLESS_ENABLED } from 'util/auth/auth0.constants';
import { getIsAuthenticatedViaNewApp } from 'util/auth/get-is-authenticated-via-new-app';

export const setLocalStorage = (details, userDetails, permissionDetails) => {
  // localStorage / redux auth token is unique to legacy auth implementation and should be removed as soon as possible
  localStorage.setItem('Authorization', userDetails.data.token);
  if (permissionDetails)
    localStorage.setItem(
      'modulePermissions',
      JSON.stringify(permissionDetails)
    );
  localStorage.setItem('role', details.role);
  localStorage.setItem(
    'role_category',
    !!details?.role_category ? details.role_category : ''
  );
  localStorage.setItem(
    'impersonateSignupFlow',
    !!details?.signupFlow ? details.signupFlow : PRODUCER_KEY
  );
  localStorage.setItem('fkParentProducerId', details.FK_parent_producer_id);
  localStorage.setItem(
    'isWholesaler',
    !!details?.is_principle_wholesaler ? details.is_principle_wholesaler : false
  );
  let appointments =
    !!details.appointments && isArray(details.appointments)
      ? details.appointments
      : [];
  localStorage.setItem('appointments', JSON.stringify(appointments));
  localStorage.setItem('isImpersonatedDataPresent', false);
  localStorage.setItem(
    'signupFlow',
    !!details?.signupFlow ? details.signupFlow : PRODUCER_KEY
  );
  localStorage.setItem('userStatus', details?.status);
  localStorage.setItem(
    'hasQuotingEngineAccess',
    !!details?.hasQuotingEngineAccess ? true : false
  );
  localStorage.setItem(
    'has_accepted_carriers_appointment_prompt',
    details?.has_accepted_carriers_appointment_prompt || false
  );
  localStorage.setItem(
    'hasAppointmentConsoleAccess',
    details?.hasAppointmentConsoleAccess || false
  );
  localStorage.setItem(
    IS_CARRIER_STORE_ENABLED_KEY,
    details?.agencyProfile?.isCarrierStoreEnabled || false
  );
  localStorage.setItem('dashboard', details?.dashboard || false);
  localStorage.setItem(
    'updateDefaultPassword',
    details?.must_update_password || false
  );
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + userDetails.data.token;
  localStorage.setItem(
    'enableMenuItem',
    details?.carrierAppointmentRequestCount >= 3 ? true : false
  );
  localStorage.setItem(
    CARRIER_APPOINTMENT_REQ_COUNT,
    details?.carrierAppointmentRequestCount || 0
  );
  localStorage.setItem(
    CARRIER_STORE_AGENT_APPLICATION_RESTRICTION_ENABLED,
    details?.carrierStoreAgentApplicationRestrictionEnabled || false
  );
  localStorage.setItem(
    'writesUnderAgencyLicense',
    details?.licenseAndEnO?.writesUnderAgencyLicense || false
  );
  localStorage.setItem('daysRemaining', details?.fastStart?.daysRemaining);
  localStorage.setItem('q3DaysRemaining', details?.q3Incentive?.daysRemaining);
  localStorage.setItem('q4DaysRemaining', details?.q4Incentive?.daysRemaining);
  localStorage.setItem(
    'isInitialModalViewed',
    details?.fastStart?.isInitialModalViewed || false
  );
  localStorage.setItem(
    'isSecondaryModalViewed',
    details?.fastStart?.isSecondaryModalViewed || false
  );
  localStorage.setItem(
    'isTertiaryModalViewed',
    details?.fastStart?.isTertiaryModalViewed || false
  );
  localStorage.setItem(
    'shouldShowBanner',
    details?.fastStart?.shouldShowBanner || false
  );
  localStorage.setItem(
    'showFastStartModal',
    details?.fastStart?.showFastStartModal || true
  );
  localStorage.setItem(
    'shouldShowFastStart',
    details?.fastStart?.shouldShowFastStart || false
  );

  localStorage.setItem(
    'q2IncentiveDaysRemaining',
    details?.q2Incentive !== null ? details?.q2Incentive?.daysRemaining : null
  );

  localStorage.setItem(
    'isQ2IncentiveInitialModalViewed',
    details?.q2Incentive?.isInitialModalViewed || false
  );
  localStorage.setItem(
    'isQ2IncentiveSecondaryModalViewed',
    details?.q2Incentive?.isSecondaryModalViewed || false
  );
  localStorage.setItem(
    'isQ2IncentiveTertiaryModalViewed',
    details?.q2Incentive?.isTertiaryModalViewed || false
  );
  localStorage.setItem(
    'isQ2IncentiveQuaternaryModalViewed',
    details?.q2Incentive?.isQuaternaryModalViewed || false
  );
  localStorage.setItem(
    'completedSignupStep',
    details?.completedSignupStep ||
      MAPPING_POST_SIGNUP_STEPS_NAME.AGENCY_PROFILE
  );
  localStorage.setItem('showQ2IncentiveModal', true);
  // @TODO: remove with https://myhippo.atlassian.net/browse/FCACT-1773
  localStorage.setItem('activatedAt', details?.activated_at ?? null);
  localStorage.setItem('earningSettings', details?.fastStart?.earningSettings);
  localStorage.setItem(
    'availableCarrierCount',
    details.status.toLowerCase() !== IN_COMPLETE
      ? details?.availableCarrierCount
      : null
  );
};

export function* setReducer(permissions, details, userDetails) {
  yield put(setModulePermissions(permissions));
  yield put(setDashboardView(details.dashboard || false));
  yield put(setIsWholesaler(details.is_principle_wholesaler));
  yield put(
    setShowFastStartBanner(details?.fastStart?.shouldShowBanner || false)
  );
  yield put(viewFastStartModal(details?.fastStart?.showFastStartModal || true));
  yield put(viewQ2IncentiveModal(true));
  if (
    isUndefined(details.agencyProfile?.businessCallOptIn) &&
    details?.status?.toLowerCase() !== TERMINATED
  ) {
    localStorage.setItem(SHOW_CONSENT_MODAL, true);
  }
  yield put(
    updateCarrierAppointmentRequestCount({
      carrierAppointmentRequestCount: details?.carrierAppointmentRequestCount,
    })
  );

  yield put(fetchProducerSettings());
  yield put(fetchProducerFlags());

  const showQuoteConnector = !!details?.show_quote_connector;
  if (showQuoteConnector) {
    yield put(fetchQuoteConnectorSettings());
  }

  yield put(
    userSignInSuccess({
      carrierAppointmentRequestCount:
        details?.carrierAppointmentRequestCount || 0,
      carrierStoreAgentApplicationRestrictionEnabled:
        details?.carrierStoreAgentApplicationRestrictionEnabled || false,
      enableMenuItem:
        details?.carrierAppointmentRequestCount >= 3 ? true : false,
      authUser: details.email,
      agencyProfile: details.agencyProfile,
      loginName: details.first_name,
      producer_id: details.producer_id,
      producer_code: details.producer_code,
      userRole: details.role_category,
      userStatus: details.status ? details.status : '',
      userLastName: details.last_name,
      complianceInfo: !isEmpty(details.complianceInfo)
        ? details.complianceInfo
        : {},
      // localStorage / redux auth token is unique to legacy auth implementation and should be removed as soon as possible
      authToken: userDetails.data.token,
      page: details.page,
      role: details.role,
      updateDefaultPassword: details.must_update_password,
      mustUpdatePassword: details.must_update_password,
      signupFlow: !!details?.signupFlow ? details.signupFlow : PRODUCER_KEY,
      taxStatus: !!details?.tax?.taxStatus
        ? isHex(details.tax.taxStatus)
          ? decryptData(details.tax.taxStatus)
          : details.tax.taxStatus
        : '',
      agencyName: !!details?.agency_name ? details.agency_name : '',
      writesUnderAgencyLicense:
        details?.licenseAndEnO?.writesUnderAgencyLicense || undefined,
      fkParentProducerId: !!details?.FK_parent_producer_id
        ? details.FK_parent_producer_id
        : null,
      hasAcceptedAgreement: details.has_accepted_agreement,
      appointments: !!details?.appointments ? details.appointments : [],
      hasQuotingEngineAccess: !!details?.hasQuotingEngineAccess ? true : false,
      has_accepted_carriers_appointment_prompt:
        details?.has_accepted_carriers_appointment_prompt || false,
      hasAppointmentConsoleAccess: details?.hasAppointmentConsoleAccess || true,
      calloutIncentives: details?.calloutIncentives,
      producerEmail: details?.email,
      crmContactId: details?.crmContactId,
      phoneNumber: details?.phone_number,
      principleFirstName: details?.principleFirstName,
      principleLastName: details?.principleLastName,
      shouldShowProgressBar: details?.shouldShowProgressBar,
      daysRemaining: details?.fastStart?.daysRemaining,
      isInitialModalViewed: details?.fastStart?.isInitialModalViewed || false,
      isSecondaryModalViewed:
        details?.fastStart?.isSecondaryModalViewed || false,
      isTertiaryModalViewed: details?.fastStart?.isTertiaryModalViewed || false,
      shouldShowFastStart: details?.fastStart?.shouldShowFastStart || false,
      completedSignupStep: details?.completedSignupStep,
      activatedAt: details?.activated_at ?? null,
      earningSettings: details?.fastStart?.earningSettings,
      availableCarrierCount: details?.availableCarrierCount,
    })
  );
  localStorage.setItem('isSignInDone', true);

  yield put(
    setQ3DaysRemaining({
      q3DaysRemaining:
        details?.q3Incentive !== null
          ? details?.q3Incentive?.daysRemaining
          : null,
    })
  );

  yield put(
    setQ4DaysRemaining({
      q4DaysRemaining:
        details?.q4Incentive !== null
          ? details?.q4Incentive?.daysRemaining
          : null,
    })
  );

  yield put(
    storeQ2IncentiveInitialDetail({
      q2IncentiveDaysRemaining:
        details?.q2Incentive !== null
          ? details?.q2Incentive?.daysRemaining
          : null,
      isQ2IncentiveInitialModalViewed:
        details?.q2Incentive?.isInitialModalViewed || false,
      isQ2IncentiveSecondaryModalViewed:
        details?.q2Incentive?.isSecondaryModalViewed || false,
      isQ2IncentiveTertiaryModalViewed:
        details?.q2Incentive?.isTertiaryModalViewed || false,
      isQ2IncentiveQuaternaryModalViewed:
        details?.q2Incentive?.isQuaternaryModalViewed || false,
      showQ2IncentiveModal: true,
    })
  );

  yield put(
    setApplicationModal(details?.shouldShowCarrierStoreFlowModal || false)
  );
}

export function* setAgreementData(details) {
  const agreementDetails = !isEmpty(
    details.latestAgreementData.lastestAgreement
  )
    ? details.latestAgreementData.lastestAgreement
    : null;
  const { signupFlow } = !!details && details;
  let isRenewalNotification =
    !isEmpty(signupFlow) && signupFlow === PRODUCER_KEY ? true : false;
  if (details.role !== ADMIN && !details.latestAgreementData.status) {
    yield put(
      renewalNotificationFun({
        producer_id: details.producer_id,
        producer_firstname: details.first_name,
        producer_lastname: details.last_name,
        producer_date: !isEmpty(agreementDetails)
          ? agreementDetails.created_at
          : '',
        id: !isEmpty(agreementDetails) ? agreementDetails.id : '',
        content: !isEmpty(agreementDetails) ? agreementDetails.content : '',
        statusD: !isEmpty(agreementDetails) ? agreementDetails.status : '',
        status: isRenewalNotification,
      })
    );
    // Producer Agreement
    let producerObj = {
      producer_id: details.producer_id,
      producer_firstname: details.first_name,
      producer_lastname: details.last_name,
      producer_date: !isEmpty(agreementDetails)
        ? agreementDetails.created_at
        : '',
      id: !isEmpty(agreementDetails) ? agreementDetails.id : '',
      content: !isEmpty(agreementDetails) ? agreementDetails.content : '',
      statusD: !isEmpty(agreementDetails) ? agreementDetails.status : '',
      status: isRenewalNotification,
    };
    localStorage.setItem('producerDetails', JSON.stringify(producerObj));
    localStorage.setItem('renewalNotificationFlag', isRenewalNotification);
  }
}

function* handleSignInResponse(response) {
  const reduxState = yield select();
  const { carrierTooltip: carrierTooltipValue } = reduxState.auth;

  yield put(setProducerLoggedIn(true));
  let permissionDetails =
    response?.permissions ??
    (!!response?.token && decryptModulePermissions(response.token)); // Decodes received permission token
  let details = response.producer;
  if (!!details) {
    const { producer_code } = details;
    FullStory.setUserVars({
      producerCode: producer_code,
    });
    localStorage.setItem('showSignupMobileTransition', false);
    localStorage.setItem(
      'signupDeviceTransitionEmail',
      details.signupDeviceTransitionEmail
    );
    localStorage.setItem(
      'isSignupDeviceTransitionViewed',
      details.isSignupDeviceTransitionViewed
    );
    localStorage.setItem(
      'shouldShowProgressBar',
      details?.shouldShowProgressBar || false
    );
    if (
      details.has_accepted_agreement &&
      details.role !== ADMIN &&
      !details.dashboard
    ) {
      yield put(
        renewalNotificationFun({
          producer_id: details.producer_id,
          producer_firstname: details.first_name,
          producer_lastname: details.last_name,
          status: false,
        })
      );
    }
    if (details.floodRaterCarrierId) {
      const { producer_id, floodRaterCarrierId: carrier_id } = details;
      const data = {
        floodRaterCarrier: {
          producer_id,
          carrier_id,
        },
      };
      yield put(setCarrierFloodRater(data));

      localStorage.setItem('floodRaterCarrier', JSON.stringify(data));
    }
    if (details.tendCarrierId) {
      const { producer_id, tendCarrierId: carrier_id } = details;
      const data = {
        tendCarrier: {
          producer_id,
          carrier_id,
        },
      };
      yield put(setCarrierTend(data));

      localStorage.setItem('tendCarrier', JSON.stringify(data));
    }
    let isConsentModalOpen =
      isUndefined(details?.agencyProfile?.businessCallOptIn) &&
      details?.status?.toLowerCase() !== TERMINATED;
    localStorage.setItem(SHOW_CONSENT_MODAL, false);
    yield put(setConsentTextFlag(isConsentModalOpen));
    if (carrierTooltipValue) {
      yield put(setCarrierTooltip(CARRIER_TOOLIP_STATE.REMOVED));
      localStorage.setItem(CARRIER_TOOLTIP, CARRIER_TOOLIP_STATE.REMOVED);
    } else {
      yield put(setCarrierTooltip(CARRIER_TOOLIP_STATE.ADDED));
      localStorage.setItem(CARRIER_TOOLTIP, CARRIER_TOOLIP_STATE.ADDED);
    }
  }
  if (details?.role_category === ADMIN) {
    try {
      const cloudFrontUrlData = yield axios.get(
        `/api/v1/common/cloudfront-url`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${response?.token}`,
          },
        }
      );
      if (cloudFrontUrlData) {
        yield put(setCloudFrontUrl(cloudFrontUrlData?.data?.cloudfrontURL));
        localStorage.setItem(
          CLOUD_FRONT_URL,
          cloudFrontUrlData?.data?.cloudfrontURL
        );
      }
    } catch (e) {
      console.error(e);
    }
  }
  yield call(setLocalStorage, details, { data: response }, permissionDetails);
  yield call(setReducer, permissionDetails, details, { data: response });
  yield call(setAgreementData, details);
  localStorage.setItem(
    'shouldShowProgressBar',
    response.producer.shouldShowProgressBar
  );
  yield put(setProgressBarFlag(response.producer.shouldShowProgressBar));
  yield put(
    setApplicationModal(details?.shouldShowCarrierStoreFlowModal || false)
  );
  yield put(setSignupTransLoginSuccess(2));

  if (!details.dashboard && !details.must_update_password) {
    yield put(
      stepDataFetch({
        producer_id: details.producer_id,
      })
    );
  }

  yield put(setStateInitialized(true));
  localStorage.setItem('isStateInitialized', true);

  // Redirect to the page that the user was on before they were prompted to login
  const { url, isExternalRoute } = getRedirectUrlFromCookie();

  if (url) {
    cleanupRedirectCookieData();
    if (isExternalRoute) {
      yield put(
        push(`${REFRESH_STATE_ROUTE}?redirectUrl=${encodeURIComponent(url)}`)
      );
    } else {
      yield put(push(url));
    }
  }
}

// 🚨 Sign In 🚨

function getCohortUrl({ email, phone }) {
  const urlBase = `${config.BFF_URL}/api/v1/producer/auth0-cohort`;
  if (email) {
    return `${urlBase}?email=${encodeURIComponent(email)}`;
  } else if (phone) {
    return `${urlBase}?phone=${encodeURIComponent(
      phone.match(/\d/g).join('')
    )}`;
  }
  return urlBase;
}

function* getCohortAndSignInUserAuth0Passwordless({ email, phoneNumber }) {
  try {
    yield put(authLoaderShow());

    if (!email && !phoneNumber) {
      throw new Error(
        'getCohortAndSignInUserAuth0Passwordless issued without email or phone number'
      );
    }

    const cohortResponse = yield call(
      axios.get,
      getCohortUrl({ email, phone: phoneNumber })
    );

    const cohort = cohortResponse.data?.cohort;
    const unsafeProducerCode = cohortResponse.data?.producer_code;
    const isVerified = cohortResponse.data?.isVerified;
    const isPasswordless = isInPasswordlessCohort(cohort);
    const isRememberMe = getIsPasswordlessRememberMe();

    yield put(
      setSignInCohortData({
        email,
        unsafeProducerCode,
        isVerified,
      })
    );

    yield call(handleSignInTracking, {
      unsafeProducerCode,
      isPasswordless,
    });

    if (isPasswordless) {
      window.heap?.track(PORTAL.AUTH_FLOW_LOGIN_GET_CODE_CLICK, {
        'Pwdless Login type': email ? 'Email' : 'Text',
        'Remember me': String(isRememberMe),
      });
      localStorage.setItem(AUTH_FLOW_PASSWORDLESS_ENABLED, 'true');

      if (email) {
        yield call(issueAuth0SpaPasswordlessLoginEmail, {
          email,
          producerCode: unsafeProducerCode,
        });
        return;
      }

      if (phoneNumber && !isVerified) {
        yield call(issueAuth0SpaPasswordlessLoginUnverifiedEmail, {
          producerCode: unsafeProducerCode,
        });
        return;
      }

      if (phoneNumber && isVerified) {
        yield call(issueAuth0SpaPasswordlessLoginPhone, {
          phone: phoneNumber,
          producerCode: unsafeProducerCode,
        });
        return;
      }

      return;
    } else {
      localStorage.setItem(AUTH_FLOW_PASSWORDLESS_ENABLED, 'false');
      yield call(issueAuth0SpaPasswordLogin, {
        email,
        producerCode: unsafeProducerCode,
      });
    }
  } catch (error) {
    logAuthError(error);
    yield call(issueAuth0SpaPasswordlessLoginEmail, {
      email,
    });
    yield put(setSignupTransLoginSuccess(1));
  }
  yield put(authLoaderHide());
}

function* signInUserAuth0({ payload }) {
  yield call(getCohortAndSignInUserAuth0Passwordless, payload);
  return;
}

export function* signInUserWatcher() {
  yield takeEvery(authType.SIGNIN_USER, signInUserAuth0);
}

// 🚨 Handle Sign In Callback 🚨

function* postSignInDataFetch() {
  yield put(setIsGlobalAuthLoaderShown(true));
  yield put(authLoaderShow());

  const spaClient = getAuth0SpaClient();

  const isAuthenticated = yield spaClient.isAuthenticated();
  if (!isAuthenticated) {
    logAuthError('Cannot obtain access token past handle Auth0 redirect.');
    yield put(setIsGlobalAuthLoaderShown(false));
    yield put(authLoaderHide());
    yield put(setSignupTransLoginSuccess(1));
    return;
  }
  const accessToken = yield spaClient.getTokenSilently();

  if (!accessToken) {
    logAuthError('Cannot obtain access token past handle Auth0 redirect.');
    yield put(setIsGlobalAuthLoaderShown(false));
    yield put(authLoaderHide());
    yield put(setSignupTransLoginSuccess(1));
    return;
  }

  try {
    const meResponse = yield call(fetchMeRequest, accessToken, {
      producer: true,
    });
    meResponse.data.token = accessToken;
    yield call(handleSignInResponse, meResponse.data);
  } catch (error) {
    logAuthError(error);
    yield put(setSignupTransLoginSuccess(1));
  }

  const isEmailVerified = getIsEmailVerifiedFromToken(accessToken);
  yield put(setIsUserEmailVerified(isEmailVerified));

  yield put(setIsGlobalAuthLoaderShown(false));
  yield put(setSignupTransLoginSuccess(1));
}

// Handle Auth0 redirect callback - SPA Password with refresh tokens or Passwordless flow (auth0-spa-js)
function* handleAuth0SignInRedirectAuth0Spa() {
  try {
    yield put(setIsGlobalAuthLoaderShown(false));

    const { shouldContinue } = yield handleAuth0SpaLoginRedirect();

    if (!shouldContinue) {
      return;
    }

    yield call(postSignInDataFetch);
  } catch (error) {
    logAuthError(error);
    if (error?.response?.status && error.response.status === 401) {
      yield put(errorHandler(error));
    } else {
      if (error.response) {
        yield put(showAuthMessage(error.response.data.message));
      } else {
        yield put(errorHandler(error));
      }
    }
    yield put(setSignupTransLoginSuccess(1));
  } finally {
    yield put(authLoaderHide());
    yield put(setIsGlobalAuthLoaderShown(false));
  }
}

function* handleRequiredDataFetchOnExternalAuth() {
  try {
    yield call(postSignInDataFetch);
  } catch (error) {
    logAuthError(error);
    if (error?.response?.status && error.response.status === 401) {
      yield put(errorHandler(error));
    } else {
      if (error.response) {
        yield put(showAuthMessage(error.response.data.message));
      } else {
        yield put(errorHandler(error));
      }
    }
    yield put(setSignupTransLoginSuccess(1));
  } finally {
    yield put(authLoaderHide());
    yield put(setIsGlobalAuthLoaderShown(false));
  }
}

// Handles the redirect from Auth0 after successful or error login.
// Runs for both legacy password flow (auth0-js) and SPA Password with refresh tokens or Passwordless flow (auth0-spa-js)
function* handleAuth0SignInRedirect() {
  yield call(handleAuth0SignInRedirectAuth0Spa);
}

export function* auth0Watcher() {
  yield takeEvery(
    authType.SIGNIN_AUTH0_HANDLE_REDIRECT,
    handleAuth0SignInRedirect
  );
}

export function* externalAuthDataFetchWatcher() {
  yield takeEvery(
    authType.SIGNIN_AUTH0_FETCH_USER_DATA,
    handleRequiredDataFetchOnExternalAuth
  );
}

// Other stuff

export function* fetchPermissionsWatcher() {
  yield takeEvery(authType.FETCH_PERMISSIONS, fetchUserPermissions);
}

const fetchMeRequest = async (token, query = {}) =>
  await axios.post(
    '/api/v1/producer/me',
    {},
    {
      params: query,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

const getIsEmailVerifiedFromToken = (token) => {
  const decodedToken = jwtDecode(token);
  return Boolean(decodedToken.verified);
};

function* fetchUserPermissions({ payload }) {
  const { token } = payload;

  try {
    yield put(authLoaderShow());

    // Handle permissions & producer data
    const meResponse = yield call(fetchMeRequest, token, { producer: true });
    if (meResponse.data) {
      localStorage.setItem(
        'availableCarrierCount',
        meResponse.data.producer.availableCarrierCount
      );
      yield put(setModulePermissions(meResponse.data.permissions));
      yield put(setProducer(meResponse.data.producer));
      yield put(fetchProducerFlags());
    }

    // Handle email verification info
    const isEmailVerified = getIsEmailVerifiedFromToken(token);
    yield put(setIsUserEmailVerified(isEmailVerified));
  } catch (error) {
    // P0 - FCACT-1115 - 401 error handling
    if (error?.response?.status && error.response.status === 401) {
      yield put(errorHandler(error));
    } else {
      if (error.response) {
        yield put(showAuthMessage(error.response.data.message));
      } else {
        yield put(errorHandler(error));
      }
    }
  }

  yield put(authLoaderHide());
}

function* autoSignInUser({ payload }) {
  const { id } = payload;
  try {
    yield put(authLoaderShow());
    const userDetails = yield axios.get(`/api/v1/producer/verify/${id}`);
    if (userDetails.data) {
      let permissionDetails =
        userDetails?.data?.permissions ??
        (!!userDetails?.data?.token &&
          decryptModulePermissions(userDetails.data.token)); // Decodes received permission token
      let details = userDetails.data.producer;
      yield call(setLocalStorage, details, userDetails, permissionDetails);
      yield call(setReducer, permissionDetails, details, userDetails);
      yield put(
        postEmailVerificationSuccess({
          username: details.first_name,
          agencyname: details.agency_name,
          producercode: details.producer_code,
        })
      );
      localStorage.setItem(
        'shouldShowProgressBar',
        userDetails.data.producer.shouldShowProgressBar
      );
      yield put(
        setProgressBarFlag(userDetails.data.producer.shouldShowProgressBar)
      );

      yield put(
        stepDataFetch({
          producer_id: details.producer_id,
        })
      );
      yield call(setAgreementData, details);
      yield put(setInitUrl(''));
      yield put(push('/post-signup'));
    } else {
      yield put(showAuthMessage(userDetails.message));
      yield put(setInitUrl('/signin'));
      yield put(push('/signin'));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(authLoaderHide());
}

export function* autoSignIn() {
  yield takeEvery(authType.AUTO_SIGNIN, autoSignInUser);
}

function* setCrossSellViewed({ payload }) {
  try {
    const response = yield axios.post(
      `/api/v1/producer/mark-cross-sell-experience-viewed`,
      payload
    );
    if (response.status !== 200) {
      yield displayErrorMessage(response.data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* setCrossSellViewedWatcher() {
  yield takeLeading(authType.SET_CROSS_SELL_VIEWED, setCrossSellViewed);
}
function* getProgressBarFlag({ payload }) {
  yield put(getProgressBarLoader(true));
  try {
    const response = yield axios.post(`/api/v1/vendor/get-progress-bar-flag`, {
      flagshipIdentifier: payload,
    });
    if (response.status === 200) {
      if (response.data.shouldShowProgressBar) {
        localStorage.setItem('shouldShowProgressBar', true);
        yield put(setProgressBarFlag(true));
      } else {
        localStorage.setItem('shouldShowProgressBar', false);
        yield put(setProgressBarFlag(false));
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(getProgressBarLoader(false));
}

export function* getProgressBarFlagWatcher() {
  yield takeLeading(authType.GET_PROGRESS_BAR_FLAG, getProgressBarFlag);
}

function* fetchNotifications() {
  try {
    const response = yield axios.get(
      `${config.BFF_URL}/api/v1/producer/notifications`
    );
    if (!!response.data) {
      const { notSeenCount } = response.data;
      document.title = notSeenCount
        ? `(${notSeenCount}) First Connect`
        : 'First Connect';
      yield put(setUnseenNotificationsCount(notSeenCount));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* fetchNotificationsWatcher() {
  yield takeLeading(authType.FETCH_NOTIFICATIONS, fetchNotifications);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield put(setIsGlobalAuthLoaderShown(getIsAuthenticatedViaNewApp()));
  yield put(authLoaderHide());
  yield all([
    fork(signInUserWatcher),
    fork(signOutSagaWatcher),
    fork(autoSignIn),
    fork(setCrossSellViewedWatcher),
    fork(getProgressBarFlagWatcher),
    fork(fetchPermissionsWatcher),
    fork(auth0Watcher),
    fork(externalAuthDataFetchWatcher),
    fork(fetchNotificationsWatcher),
  ]);
}
