// @ts-check
import {NavLink} from "react-router-dom";
import {
  MODULE_TO_TITLE_MAP,
  SECONDARY_NAVIGATION_MODULES,
} from './side-nav.constants';
import { navigateToNewAppRoute } from '../../util/navigate-to-new-app-route';
import { PillLabel } from '../../components/pill-label';
import React, { useEffect } from 'react';
import './secondary-navigation.scss';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { Fade } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getCarrierLink } from '../../redux/actions';

/**
 * @param {object} props
 * @param {'mobile' | 'desktop'} props.mode
 * @param {() => void} props.onClose
 * @param {boolean} props.shouldDisplay
 * @param {string} props.parentModule
 */
export default function SecondaryNavigation({
  mode,
  onClose,
  shouldDisplay,
  parentModule,
}) {
  const secondaryNavigationModules = usePermittedSubmodules();

  useEffect(function cleanUp() {
    return () => {
      onClose();
    };
  }, []);

  return (
    <Fade in={shouldDisplay} unmountOnExit mountOnEnter>
      <ul
        id="secondary-navigation"
        className={`secondary-navigation--${mode} nav-menu`}
        onMouseLeave={() => {
          if (mode === 'desktop') {
            onClose();
          }
        }}
      >
        <div className="secondary-navigation__title-container">
          {mode === 'mobile' && (
            <button
              className="secondary-navigation__close-button"
              onClick={onClose}
            >
              <KeyboardArrowLeft />
            </button>
          )}
          <span>{MODULE_TO_TITLE_MAP[parentModule]}</span>
        </div>
        {secondaryNavigationModules[parentModule]?.map((submodule) => (
          <Submodule key={submodule.url} submodule={submodule} mode={mode} />
        ))}
      </ul>
    </Fade>
  );
}

function usePermittedSubmodules() {
  return Object.fromEntries(
    Object.entries(SECONDARY_NAVIGATION_MODULES).map(([module, submodules]) => {
      return [
        module,
        submodules.filter((submodule) =>
          submodule.getIsPermitted ? submodule.getIsPermitted() : true
        ),
      ];
    })
  );
}

function Submodule({ submodule, mode }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const floodRaterCarrier = auth?.floodRaterCarrier;
  const carrierId = floodRaterCarrier?.carrier_id;
  const producerId = floodRaterCarrier?.producer_id;
  const role = localStorage.getItem('role_category');

  // Special plugin related navigation (my account and plugin settings)
  if (submodule.url === '#my-account') {
    const url = `/app/${role}/my-account`;

    return (
      <li className="menu no-arrow">
        <NavLink
          to={url}
          className="secondary-navigation__link"
        >
          {<submodule.IconComponent />}
          <span className="nav-text">{submodule.title}</span>
          {submodule.pillText && mode === 'desktop' && (
            <PillLabel styles={{ marginLeft: 'auto' }}>
              {submodule.pillText}
            </PillLabel>
          )}
        </NavLink>
      </li>
    )
  }

  const handleClick = (e) => {
    e.preventDefault();
    if (submodule.url === '#flood-rater') {
      dispatch(
        getCarrierLink({
          carrier_id: carrierId,
          producer_id: producerId,
          isFromNavItem: true,
        })
      );
    } else {
      navigateToNewAppRoute(submodule.url);
    }
  };

  return (
    <li className="menu no-arrow">
      <a
        href={submodule.url}
        className="secondary-navigation__link"
        onClick={handleClick}
      >
        {<submodule.IconComponent />}
        <span className="nav-text">{submodule.title}</span>
        {submodule.pillText && mode === 'desktop' && (
          <PillLabel styles={{ marginLeft: 'auto' }}>
            {submodule.pillText}
          </PillLabel>
        )}
      </a>
    </li>
  );
}
