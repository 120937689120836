// @ts-check

/** @type {import("./launch-darkly-types").TLaunchDarklyFlag<boolean>} */
const IS_NEW_POLICIES_PAGE_ENABLED = {
  key: 'isNewPoliciesPageEnabled',
  defaultValue: false,
};

/** @type {import("./launch-darkly-types").TLaunchDarklyFlag<boolean>} */
const IS_NEW_SIGN_UP_ENABLED = {
  key: 'isNewSignUpEnabled',
  defaultValue: false,
};

const IS_LEAD_COLLECTOR_PUBLIC_PAGE_ENABLED = {
  key: 'isLeadCollectorPublicPageEnabled',
  defaultValue: false,
};

/** @type {import("./launch-darkly-types").TLaunchDarklyFlag<boolean>} */
const IS_LEAD_COLLECTOR_CONTACTS_PAGE_ENABLED = {
  key: 'isLeadCollectorContactsPageEnabled',
  defaultValue: false,
};

export const LAUNCHDARKLY_FLAGS = {
  IS_NEW_SIGN_UP_ENABLED,
  IS_NEW_POLICIES_PAGE_ENABLED,
  IS_LEAD_COLLECTOR_PUBLIC_PAGE_ENABLED,
  IS_LEAD_COLLECTOR_CONTACTS_PAGE_ENABLED,
};
