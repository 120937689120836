// @ts-check

import { LAUNCHDARKLY_FLAGS } from 'containers/launch-darkly/launch-darkly-flags';
import { useLaunchDarklyFlag } from 'containers/launch-darkly/launch-darkly-provider.hooks';
import React from 'react';

export const CrmEnabledContext = React.createContext(false);

const WithCrmEnabledFlagContext = ({ children }) => {
  const isLeadCollectorPublicPageEnabled = useLaunchDarklyFlag(
    LAUNCHDARKLY_FLAGS.IS_LEAD_COLLECTOR_PUBLIC_PAGE_ENABLED
  );
  const isLeadCollectorContactsPageEnabled = useLaunchDarklyFlag(
    LAUNCHDARKLY_FLAGS.IS_LEAD_COLLECTOR_CONTACTS_PAGE_ENABLED
  );

  return (
    <CrmEnabledContext.Provider
      value={
        isLeadCollectorPublicPageEnabled && isLeadCollectorContactsPageEnabled
      }
    >
      {children}
    </CrmEnabledContext.Provider>
  );
};

export default WithCrmEnabledFlagContext;
