// @ts-check

import * as authType from 'constants/action-types';
import { SIGNOUT_ROUTE } from 'constants/routes';
import { takeLeading } from 'redux-saga/effects';

function signOutSaga() {
  window.location.href = SIGNOUT_ROUTE;
}

export function* signOutSagaWatcher() {
  yield takeLeading(authType.SIGNOUT_USER, signOutSaga);
}
