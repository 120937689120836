import { SIGNIN_ROUTE } from 'constants/routes';
import { useEffect } from 'react';
import { navigateToNewAppRoute } from 'util/navigate-to-new-app-route';

export function NewSignInRedirectWrapper({ children }) {
  useEffect(() => {
    // Get query params from the URL and remove the initial '?' character
    const queryParams = window.location.search.slice(1);
    navigateToNewAppRoute(SIGNIN_ROUTE, queryParams);
  }, []);

  return children;
}
